import { breakpointNames, type AdSize, type AdSizes } from '@/types/AdSizes';

export default function getSlotSizes(adSizes: AdSizes): AdSize[] {
  const finalSizes: AdSize[] = [];

  Object.entries(adSizes).forEach(([bp, sizes]) => {
    if (breakpointNames[bp] && !!sizes) {
      finalSizes.push(...sizes);
    }
  });

  // TODO: remove duplicates. Or do we even care? Does google? Maybe not. IAB ad size standardization will make this a non-issue?
  // finalSizes.filter() convert to string -> new Set() => split the strings back to arrays. Handle 'fluid' special case.

  return finalSizes;
}
