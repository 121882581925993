'use client';

import { type ReactNode } from 'react';
import cx from 'clsx';

import Ad from '../Ad';
import { type AdContainerProps } from './AdContainer.props';

// import { NextLink } from '../NextLink';

const AdContainer = ({
  adContainerProps,
  adProps,
}: AdContainerProps): ReactNode => {
  // --- Temporary Commenting out IDS Container Logic ---
  // const [isLoading, setIsLoading] = useState(true);
  // const [isAdEmpty, setIsAdEmpty] = useState(false);

  // const { onAdRendered } = adProps;

  // TODO: handle ad error as well...somehow...
  // const handleAdRender = useCallback(
  //   (isEmpty: boolean) => {
  //     setIsAdEmpty(isEmpty);
  //     setIsLoading(false);
  //     if (onAdRendered) {
  //       onAdRendered(isEmpty);
  //     }
  //   },
  //   [onAdRendered],
  // );

  // useEffect(() => {
  //   // Wait 5 seconds to render ad blocker fallback
  //   setTimeout(() => {
  //     if (!window.adsNotBlocked) {
  //       setIsLoading(false);
  //       setIsAdEmpty(true);
  //     }
  //   }, 5000);
  // }, []);

  return (
    // --- Temporary Commenting out IDS Container ---

    // <AdContainerInner
    //   {...adContainerProps}
    //   fallback={isAdEmpty || undefined}
    //   fallbackLinkElement={NextLink}
    //   loading={isLoading}
    // >
    <div
      className={cx(
        'flex items-center justify-center overflow-hidden',
        adContainerProps?.className,
      )}
    >
      <Ad {...adProps} />
    </div>
    // </AdContainerInner>
  );
};

export default AdContainer;
