import {
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
  type FunctionComponent,
} from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { ConditionalWrapper, useIntersection } from '@motortrend/ids';

import { type AdTargeting } from '@/types/AdTargeting';

import getSizeMap from '@/utils/ads/getSizeMap';
import getSlotSizes from '@/utils/ads/getSlotSizes';
import { isEmptyObject, sanitizeAdPath } from '@/utils/ads/sanitizeAdPath';
import { adLog } from '@/utils/grafana/adsTrace';

import { type AdProps } from './Ad.props';

const ReconstructiveAd: FunctionComponent<AdProps> = ({
  additionalAdPath,
  className,
  destroyRefresh,
  onAdRendered,
  pageTargeting,
  sizes,
  targeting,
}) => {
  const searchParams = useSearchParams();
  const debug = searchParams?.get('adDebug');
  const ref = useRef(null);

  const slotId = useId();
  const currentPath = usePathname() || '';
  const slotRef = useRef(false);

  const adIntersection = useIntersection(ref);
  const isVisible = adIntersection?.isIntersecting;
  const [shouldBeDestroyed, setShouldBeDestroyed] = useState(false);

  const finalTargeting = useMemo(() => {
    // Start with the first defined targeting object (from pageTargeting, adTargeting, or initialAdConfig)
    const baseTargeting: Record<string, unknown> =
      (!isEmptyObject(pageTargeting?.adTargeting) &&
        pageTargeting?.adTargeting) ||
      {};

    // Return the merged object with targeting taking precedence, while keeping baseTargeting as the foundation
    return {
      ...baseTargeting,

      ...targeting, // Always included, taking precedence
    } as AdTargeting;
  }, [pageTargeting?.adTargeting, targeting]);

  const slotSizes = useMemo(() => {
    return getSlotSizes(sizes);
  }, [sizes]);

  const sizeMap = useMemo(() => {
    return getSizeMap(sizes);
  }, [sizes]);

  const finalAdPath = useMemo(() => {
    const path = pageTargeting?.adPath;

    // If no valid adPath is available, return undefined
    if (!path) {
      return;
    }

    return sanitizeAdPath(path, finalTargeting, additionalAdPath, currentPath);
  }, [pageTargeting?.adPath, finalTargeting, additionalAdPath, currentPath]);

  useEffect(() => {
    const handleSlotRenderEnded = (
      event: googletag.events.SlotRenderEndedEvent,
    ) => {
      const { isEmpty, slot } = event;
      if (slot.getSlotElementId() === slotId) {
        if (onAdRendered) {
          onAdRendered(isEmpty);
        }
      }
    };
    // only use this if a callback is defined
    if (onAdRendered) {
      const googletag = window.googletag || { cmd: [] };
      googletag
        .pubads()
        .addEventListener('slotRenderEnded', handleSlotRenderEnded);
    }

    // Cleanup function to destroy the ad slot on component unmount
    return () => {
      if (onAdRendered) {
        const googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(() => {
          googletag
            .pubads()
            .removeEventListener('slotRenderEnded', handleSlotRenderEnded);
        });
      }
    };
  }, [onAdRendered, slotId]);

  useEffect(() => {
    if (!destroyRefresh || !finalAdPath) {
      return;
    }

    // For destroy refresh ads, we cannot rely on GAM lazy loading
    // Instead, we manually call `display` on the ad when it is in the viewport
    // When an ad is out of viewport, but we have already called `display` on it,
    //     we destroy that ad slot and replace it with an identical one
    // When that refreshed ad is nearing the viewport again, we manually call display on it

    if (isVisible && !shouldBeDestroyed) {
      setShouldBeDestroyed(true);
    } else if (!isVisible && (shouldBeDestroyed || !slotRef.current)) {
      setShouldBeDestroyed(false);

      window.googletag.cmd.push(() => {
        const slot = window.googletag
          .pubads()
          .getSlots()
          .find((slot: googletag.Slot) => slot.getSlotElementId() === slotId);

        if (slot) {
          adLog('AdSlotDestroyed', {
            slotId,
          });

          window.googletag.destroySlots([slot]);
        }
        slotRef.current = true;
        // build slot
        const googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(function () {
          // slot creation
          const newSlot = googletag.defineSlot(finalAdPath, slotSizes, slotId);
          adLog('AdSlotCreationRequest', {
            adUnitPath: finalAdPath,
            size: slotSizes,
            slotId,
          });
          if (newSlot) {
            newSlot.addService(googletag.pubads());

            const sizeMapper = googletag.sizeMapping();
            sizeMap.forEach(({ minWidth, sizeArray }) => {
              sizeMapper.addSize([minWidth, 1], sizeArray);
            });
            const sizeMapping = sizeMapper.build();

            if (sizeMapping) {
              newSlot.defineSizeMapping(sizeMapping);
            }
            // removing single request as its causing wrong ads to be served
            // googletag.pubads().enableSingleRequest();
            googletag.enableServices();
            // ad targeting
            Object.entries(finalTargeting).forEach(([key, value]) => {
              newSlot.setTargeting(key, value);
            });
            // ad display as a separate command
            googletag.cmd.push(function () {
              adLog('AdDisplayRequest', {
                slotId,
              });
              googletag.display(slotId);
            });
          }
          // Cleanup function to destroy the ad slot on component unmount
          return () => {
            if (typeof window !== 'undefined' && window.googletag) {
              window.googletag.cmd.push(() => {
                window.googletag.destroySlots();
              });
            }
          };
        });
      });
    }
  }, [
    destroyRefresh,
    shouldBeDestroyed,
    slotId,
    isVisible,
    finalAdPath,
    slotSizes,
    sizeMap,
    finalTargeting,
  ]);

  return (
    <ConditionalWrapper
      condition={!!debug}
      wrapper={(children) => (
        <div className="min-w-100 min-h-100 relative" data-debug>
          {children}
          <div className="absolute inset-0 z-50 overflow-y-scroll whitespace-pre text-wrap bg-neutral-8 opacity-80">
            {JSON.stringify(finalTargeting, null, '  ')}
            {'\n'}
            {JSON.stringify(sizes, null, '  ')}
            {'\n'}
            {pageTargeting?.adPath} {additionalAdPath}
          </div>
        </div>
      )}
    >
      <div className={className} data-ad id={slotId} ref={ref} />
    </ConditionalWrapper>
  );
};

export default ReconstructiveAd;
