import { type FunctionComponent } from 'react';

import { type AdDebugProps } from './Ad.props';

const AdDebug: FunctionComponent<AdDebugProps> = ({
  adPath,
  children,
  sizes,
  targeting,
}) => {
  return (
    <div className="min-w-100 min-h-100 relative" data-debug>
      {children}
      <div className="absolute inset-0 z-50 overflow-y-scroll whitespace-pre text-wrap bg-neutral-8 text-neutral-1 opacity-80">
        {JSON.stringify(targeting, null, '  ')}
        {'\n'}
        {JSON.stringify(sizes, null, '  ')}
        {'\n'}
        {adPath}
      </div>
    </div>
  );
};

export default AdDebug;
