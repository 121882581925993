type FluidAdSize = 'fluid';
type SpecificAdSize = [number, number];
export type AdSize = FluidAdSize | SpecificAdSize;

export type SizeMapping = {
  minWidth: number;
  sizeArray: AdSize[] | never[];
};

export type Breakpoint = 'mobile' | 'laptop' | 'desktop' | 'tablet';

export const breakpointNames: Record<string, Breakpoint> = {
  desktop: 'desktop',
  laptop: 'laptop',
  mobile: 'mobile',
  tablet: 'tablet',
};

export type AdSizes = {
  [K in Breakpoint]?: AdSize[] | [];
};
