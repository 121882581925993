/**
 * If you don't have any slots defined during the initial setup, it's best to
 * delay calling enableServices() until you have defined at least one slot. GPT
 * services are designed to manage and display ads within defined slots. Calling
 * enableServices() before any slots exist wouldn't have the desired effect as
 * there would be no slots for the services to operate on. Once you have defined
 * your first slot using googletag.defineSlot(), you can then safely call
 * enableServices() to initialize the services and prepare them to handle ad
 * requests for your slot. - Google's GPT Bot
 *
 * @function gptEnableServices
 *
 * @returns {void}
 */
const gptEnableServices = (): void => {
  // The `pubadsReady` property is a flag indicating that PubAdsService is
  // enabled, loaded and fully operational. This property will be simply
  // undefined until enableServices is called and PubAdsService is loaded and
  // initialized.
  if (!googletag.pubadsReady) {
    googletag.enableServices();
  }
};

export default gptEnableServices;
