'use client';

import {
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
  type FunctionComponent,
} from 'react';
import { LayoutGrid, LayoutGridContent, LayoutGridRail } from '@motortrend/ids';
import cx from 'clsx';

import InfiniteAdRail from '@/components/InfiniteAdRail';

import { usePhotoGalleryContext } from '../PhotoCollection/PhotoGalleryContext';
import { type ContentWithRailProps } from './ContentWithRail.props';

const ContentWithRail: FunctionComponent<ContentWithRailProps> = ({
  ChildComponent,
  children,
  className,
  isBuyersGuidePages,
  isCertified,
  overrideBlocksToInsert,
  pageTargeting,
  rightRailClasses,
  shouldAddLeftRail = false,
  sponsored,
}) => {
  const generatedId = useId();
  const bodyRef = useRef<HTMLDivElement>(null);
  const [bodyHeight, setBodyHeight] = useState(0);
  const { currentPhotoIndex, currentTab } = usePhotoGalleryContext();
  const [adKey, setAdKey] = useState(`${currentPhotoIndex}-${currentTab}`);

  useEffect(() => {
    googletag.cmd.push(() => {
      googletag.destroySlots();
    });
  }, []);

  useEffect(() => {
    setAdKey(`${currentPhotoIndex}-${currentTab}-${Date.now()}`);
  }, [currentPhotoIndex, currentTab]);

  useLayoutEffect(() => {
    setBodyHeight(bodyRef?.current?.clientHeight || 0);
  }, []);

  return (
    <LayoutGrid
      className={cx({ '!grid-cols-[300px_auto_300px]': shouldAddLeftRail })}
    >
      {shouldAddLeftRail && (
        <LayoutGridRail className="hidden 2xl:block 2xl:min-h-[874px]">
          <InfiniteAdRail
            ChildComponent={ChildComponent}
            additionalTargeting={
              shouldAddLeftRail ? { column: 'leftrail' } : {}
            }
            bodyHeight={bodyHeight}
            className={rightRailClasses}
            isBuyersGuidePages={isBuyersGuidePages}
            isCertified={isCertified}
            key={adKey || generatedId}
            overrideBlocksToInsert={overrideBlocksToInsert}
            pageTargeting={pageTargeting}
            sponsored={sponsored}
          />
        </LayoutGridRail>
      )}
      <LayoutGridContent
        className={className}
        data-nitrous-content-readable
        ref={bodyRef}
      >
        {children}
      </LayoutGridContent>
      <LayoutGridRail className="hidden lg:block lg:min-h-[874px]">
        <InfiniteAdRail
          ChildComponent={ChildComponent}
          additionalTargeting={shouldAddLeftRail ? { column: 'rightrail' } : {}}
          bodyHeight={bodyHeight}
          className={rightRailClasses}
          isBuyersGuidePages={isBuyersGuidePages}
          isCertified={isCertified}
          key={adKey || generatedId}
          overrideBlocksToInsert={overrideBlocksToInsert}
          pageTargeting={pageTargeting}
          sponsored={sponsored}
        />
      </LayoutGridRail>
    </LayoutGrid>
  );
};

export default ContentWithRail;
