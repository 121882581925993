import { tailwindConfig } from '@/tailwind';

import {
  breakpointNames,
  type AdSizes,
  type Breakpoint,
  type SizeMapping,
} from '@/types/AdSizes';

const breakpointMap = tailwindConfig.theme.screens;

const screenSizes = Object.values(breakpointMap)
  .map((bp) => bp.replace('px', ''))
  .sort((a, b) => Number(a) - Number(b));

const breakpoints: Record<Breakpoint, number> = Object.freeze({
  // Tailwind default and sm
  mobile: 0,
  // md
  tablet: Number(screenSizes[1]),
  // lg
  laptop: Number(screenSizes[2]), // eslint-disable-line sort-keys
  // xl
  desktop: Number(screenSizes[3]), // eslint-disable-line sort-keys
  // 2xl will use xl ad sizes
});

export default function getSizeMap(sizes: AdSizes): SizeMapping[] {
  return Object.keys(sizes)
    .filter((bp) => breakpoints.hasOwnProperty(breakpointNames[bp]))
    .map((bp) => {
      return {
        minWidth: breakpoints[breakpointNames[bp]],
        sizeArray: sizes[breakpointNames[bp]] || [],
      };
    });
}
