import {
  createContext,
  useContext,
  useState,
  type FC,
  type ReactNode,
} from 'react';

const PhotoGalleryContext = createContext<{
  currentPhotoIndex: number;
  setCurrentPhotoIndex: (index: number) => void;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}>({
  currentPhotoIndex: 0,
  currentTab: 'all',
  setCurrentPhotoIndex: () => {},
  setCurrentTab: () => {},
});

/**
 * This file defines a React context and provider for managing the state of a photo collection viewer.
 * It includes:
 *
 * 1. `PhotoGalleryContext`:
 * A React context that holds the current photo index and tab, along with setter functions.
 *
 * 2. `PhotoGalleryPageProvider`:
 * A component that wraps its children with the `PhotoGalleryContext.Provider`,
 * managing the state for the current photo index and tab.
 *
 * 3. `usePhotoGalleryContext`:
 * A custom hook that allows easy access to the `PhotoGalleryContext` values in child components.
 */
export const PhotoGalleryPageProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');

  return (
    <PhotoGalleryContext.Provider
      value={{
        currentPhotoIndex,
        currentTab,
        setCurrentPhotoIndex,
        setCurrentTab,
      }}
    >
      {children}
    </PhotoGalleryContext.Provider>
  );
};

/**
 * Custom hook to use the PhotoGalleryContext
 * @returns {Object} The PhotoGalleryContext value
 */
export const usePhotoGalleryContext = () => useContext(PhotoGalleryContext);
