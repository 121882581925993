'use client';

import { useMemo } from 'react';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';

import {
  getUtmParameters,
  TargetingQueryParams,
} from '@/utils/getClientUrlParams';

const convertSearchParamsToObject = (
  searchParams: ReadonlyURLSearchParams | null,
): { [key: string]: string | string[] | undefined } => {
  const query: { [key: string]: string | string[] | undefined } = {};

  if (!searchParams) {
    return query;
  }

  // Iterate over the searchParams
  searchParams.forEach((value, key) => {
    // If the key already exists, convert it to an array (or add to existing array)
    if (query[key]) {
      if (Array.isArray(query[key])) {
        (query[key] as string[]).push(value);
      } else {
        query[key] = [query[key] as string, value];
      }
    } else {
      query[key] = value;
    }
  });

  return query;
};

const useTargetingQueryParams = () => {
  const searchParams = useSearchParams();

  return useMemo(() => {
    const params: TargetingQueryParams = {
      ...(getUtmParameters(convertSearchParamsToObject(searchParams)) || {}),
    };

    if (searchParams) {
      ['dyn', 'ppc', 'sm_id', 'test'].forEach((key) => {
        const value = searchParams.get(key);
        if (value !== null) {
          params[key as keyof TargetingQueryParams] = value;
        }
      });
    }

    return params;
  }, [searchParams]); // Recalculate only when router.query changes
};

export default useTargetingQueryParams;
