import { Routes } from '@/constants/routes';

export const sanitizeAdPath = (
  adPath: string,
  targeting: { [key: string]: string | string[] },
  additionalAdPath?: string,
  currentPath?: string,
): string => {
  // Remove possible '/null' from the end of adPath initially
  const sanitizedPath = adPath.replace(/\/null$/, '');
  const bodyStyleOnly =
    Object.keys(targeting).includes('bodystyleonly') || false;

  if (additionalAdPath === 'wallpaper') {
    return `${sanitizedPath}/${additionalAdPath}`;
  }

  if (additionalAdPath === 'bottomadhesion') {
    if (
      currentPath === Routes.BuyersGuideHomepage ||
      bodyStyleOnly ||
      currentPath?.includes(Routes.Price)
    ) {
      return `${sanitizedPath}/null/${additionalAdPath}`;
    }
    return `${adPath}/${additionalAdPath}`;
  }

  // Return the sanitized path if no additionalAdPath matches
  return sanitizedPath;
};

export const isEmptyObject = (
  obj: Record<string, unknown> | undefined,
): boolean => {
  return !obj || Object.keys(obj).length === 0;
};
