'use client';

import { useEffect, useState, type FunctionComponent } from 'react';
import clsx from 'clsx';
import { withErrorBoundary } from 'react-error-boundary';

import { NoUiErrorFallback } from '@/components/ErrorFallback';

import { type StickyAdProps } from './StickyAd.props';

const StickyAd: FunctionComponent<StickyAdProps> = ({
  children,
  className = '',
  timeout = 5000,
}) => {
  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSticky(false);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  return (
    <div
      className={clsx(
        'top-0',
        className,
        isSticky &&
          'sticky z-10 [&>div]:bg-opacity-60 [&>div]:backdrop-blur-sm',
      )}
    >
      {children}
    </div>
  );
};

export default withErrorBoundary(StickyAd, {
  FallbackComponent: NoUiErrorFallback,
});
